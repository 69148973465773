<template>
  <v-app-bar app class="c-title"  height="95">
    <div class="d-flex align-center" style="background-color: white;margin-left: -10px; ">
      <v-img style="width: 130px; height: 94px"
        alt="School Logo"
        class="shrink mr-2"
        contain
        :src="org.logo"
        transition="scale-transition"
       
      />
    </div>
    
    <v-spacer></v-spacer>

   
  </v-app-bar>
</template>
<script>
import axios from 'axios'
export default {
  data(){
    return{
      org:{
        logo:""
      }
    }
  },
  mounted(){
    this.org.logo = "https://easyplacement.s3.ap-south-1.amazonaws.com/logo/epclogo.jpg"
    // axios.post("/login/login",{
    //   dns: window.location.hostname
    // })
    // .then(res => {
    //     if(res.data.msg=="200"){
    //       this.org.logo = res.data.logo
    //       localStorage.setItem("EPS-org-logo",res.data.logo)
    //     }
    //     else{
    //       localStorage.setItem("EPS-org-logo",'')
    //       this.org.logo = "https://edupluscampus.com/img/logos/logo-white.png"
    //     }
    //   })
    // .catch()
  }
}
</script>
<style scoped>
.c-title{
        background-image: linear-gradient(-90deg, #8edffd, #034c70);
        color: #FFF;
    }
</style>