<template>
    <!-- <v-footer color="primary" dark class="justify-center c-title">
      <v-btn
        href="#"
        
        text
        style="text-transform: none;font-size: 12px">© {{ new Date().getFullYear() }} All Rights Reserved @ VI Groups</v-btn>
    </v-footer> -->
</template>
<script>
export default {
    
}
</script>
<style scoped>
.c-title{
        background-image: linear-gradient(-90deg, #8edffd, #034c70);
        color: #FFF;
    }
</style>