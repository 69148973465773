<template>
<v-app>
  <eps-login-header> </eps-login-header>
    <v-content>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="5">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Change Password</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field 
                    v-model="user.password" 
                    :rules="[v => !!v || 'Password is required']"
                    prepend-icon=" mdi-account-key"
                    placeholder="Password"
                    clearable
                    :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'"
                    hint="In 8 digit password, Atleast 1 number, special characters and small/ capital letters required."
                    required>
                  </v-text-field>
                  <v-text-field 
                    v-model="user.confirmpassword" 
                    :rules="[v => !!v || 'Confirm Password is required']"
                    prepend-icon=" mdi-account-key"
                    placeholder="Confirm Password"
                    clearable
                    :append-icon="value1 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (value1 = !value1)"
                    :type="value1?'password':'text'"
                    hint="In 8 digit password, Atleast 1 number, special characters and small/ capital letters required."
                    required>
                  </v-text-field>
                  <div class="error-message" v-if="error"> {{error_message}} </div>
                  <v-layout align-center justify-center>
                      <v-btn class="mr-4" color="primary" @click="setpassword">Change Password</v-btn>
                  </v-layout>
                </v-form>
              </v-card-text>
              <router-link to="/" style="text-decoration: none" title="Back"> <v-icon large color="green darken">mdi-chevron-left-circle</v-icon></router-link> 
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  <eps-login-footer> </eps-login-footer>
</v-app>
</template>
<script>
import axios from 'axios'
import epsLoginHeader from './LoginHeader.vue';
import epsLoginFooter from './LoginFooter.vue';

export default {
    components:{
      epsLoginHeader:epsLoginHeader,
      epsLoginFooter:epsLoginFooter,
    },
    data(){
      return{
        user:{
            uid:'',
            password:'',
            confirmpassword:'',
        },
        value: String,
        value1: String,
        message:'',
        error:false,
        error_message:'',
      }
    },
    methods: {
    setpassword() {
      if (this.user.password == this.user.confirmpassword && this.user.password.length > 0 && this.user.confirmpassword.length > 0) {
        if (this.perormChangePasswordFormValidation()) {
         // console.log("I am in If block :: " + this.user.password + " :: "+  this.user.confirmpassword );
          this.user.dns = window.location.hostname;
          this.user.uid = localStorage.getItem('EPS-uid');
        //  console.log("this.user.uid :: " + this.user.uid);
          axios
            .post("/login/changepassword", this.user)
            .then(res => {
           //   window.console.log("res" + res.data.msg);
              if (res.data.msg == "200") {
                //this.$router.push("/home");
                localStorage.setItem("EPS-uid", "");
                //window.location="/";
                this.$router.push("/");
              } else {
                this.message = "Password not Match!!!";
              }
            })
            .catch(error => {
              window.console.log(error);
              this.message = "Something went wrong";
            });
        } else {
         // console.log("I am in else block :: " + this.user.password);
        this.error = true;
        this.error_message = 'Password Updation Failed.. Please Try Again..';
        }
      } else {
        // console.log("I am in else block :: " + this.user.password);
        // console.log("-->" + this.user.password.length)
        this.error = true;
        this.error_message = 'Password Not Matched..!';
      }
    },
    perormChangePasswordFormValidation() {
      if(this.user.password=="" && this.user.confirmpassword=="")
      {
        return false;
      }
      else{
       return true;
      }
    },
    showPassword() {
        if(this.type === 'password') {
            this.type = 'text';
            this.btnText = 'Hide Password';
        } else {
            this.type = 'password';
            this.btnText = 'Show Password';
        }
    },
  },
  mounted() {
    this.message=localStorage.getItem('login-page-msg')
  }
}
</script>
<style scoped>
    .pull-right{
        position: relative;
        text-align: center;
    }
    .error-message {
      color : red;
      font-size : 14px;
      font-weight : 900;
      padding-left : 7%;
    }
</style>